<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <back-navigation
          title="Edit Customer"
          prevent-back
          @preventBack="$bvModal.show('preventBack')"
        />
        <b-modal
          id="preventBack"
          title="Ingin keluar?"
          centered
        >
          Yakin ingin keluar? Progress pengisian form anda belum disimpan.
          <template
            #modal-footer
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$bvModal.hide('preventBack'); $router.go(-1)"
            >
              Ya, keluar
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$bvModal.hide('preventBack')"
            >
              Tetap Disini
            </b-button>
          </template>
        </b-modal>
        <validation-observer ref="validateCustomer">
          <b-form @submit.prevent>
            <b-row>
              <XyzTransition
                appear
                xyz="fade left-3 delay-2"
              >
                <b-col
                  cols="21"
                  xl="8"
                >
                  <b-card title="Profile Customer">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Kode"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Kode"
                            rules="required|max:10"
                          >
                            <b-form-input
                              ref="inputKode"
                              v-model="userData.kode"
                              placeholder="Kode Pelanggan"
                              :state="errors.length > 0 ? false : errorCustomerNo ? false : userData.kode && !loadingCheckCustomerNo ? true : null"
                              @change="checkCustomerNo"
                            />
                            <small
                              class="text-danger d-block mt-25"
                              v-text="errorCustomerNo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Nama"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Nama"
                            rules="required|min:2|max:50"
                          >
                            <b-form-input
                              v-model="userData.nama"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Nama Pelanggan"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Kategori"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="userData.kategori"
                            label="name"
                            :options="customerCategory"
                            placeholder="Kategori"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Kategori Harga"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Kategori Harga"
                            rules="required"
                          >
                            <v-select
                              v-model="userData.kategori_harga"
                              label="name"
                              :options="priceCategory"
                              placeholder="Kategori Harga"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Limit Piutang"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Limit"
                            rules="integer|required|min_value:0|max_value:999999999999"
                          >
                            <b-input-group prepend="Rp.">
                              <cleave
                                v-model="userData.limitPiutang"
                                class="form-control"
                                :raw="true"
                                placeholder="Limit Piutang"
                                :options="cleaveFormat.limitPiutang"
                              />
                            </b-input-group>
                            <small class="text-danger d-block">{{ errors[0] }}</small>
                            <small class="text-secondary">Minimal 100.000, maksimal 999.999.999.999</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card title="Informasi Tambahan">
                    <b-row>
                      <b-col rows="2">
                        <b-form-group
                          label="Email"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            rules="email"
                          >
                            <b-form-input
                              v-model="userData.email"
                              placeholder="Email Pelanggan"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Work Phone"
                          label-cols-md="4"
                        >
                          <b-form-input
                            v-model="userData.workPhone"
                            type="number"
                            placeholder="Work Phone Number"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Mobile Phone"
                          label-cols-md="4"
                        >
                          <b-form-input
                            v-model="userData.mobilePhone"
                            type="number"
                            placeholder="Mobile Phone"
                          />
                        </b-form-group>
                        <b-form-group
                          label="NPWP"
                          label-cols-md="4"
                        >
                          <cleave
                            v-model="userData.npwp"
                            class="form-control"
                            :raw="true"
                            placeholder="Nomor NPWP"
                            :options="cleaveFormat.npwp"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Alamat"
                          label-cols-md="4"
                        >
                          <b-form-input
                            v-model="userData.alamat"
                            placeholder="Alamat Pelanggan"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Kota"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="userData.kota"
                            label="name"
                            :options="cities"
                            placeholder="Kota"
                            :clearable="false"
                            @option:selected="getRegionPair()"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Provinsi"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="userData.provinsi"
                            disabled
                            label="name"
                            :options="provinces"
                            placeholder="Provinsi"
                            :clearable="false"
                          />
                        </b-form-group>
                        <b-form-group
                          label="Negara"
                          label-cols-md="4"
                        >
                          <v-select
                            v-model="userData.negara"
                            disabled
                            label="name"
                            :options="countries"
                            placeholder="Negara"
                            :clearable="false"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                  <b-card title="Informasi Accurate">
                    <b-row>
                      <b-col rows="2">
                        <b-form-group
                          label="Payment Term"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Payment Term"
                            rules="required"
                          >
                            <v-select
                              v-model="userData.paymentTerm"
                              label="name"
                              :options="paymentTerm"
                              placeholder="Payment Term"
                              :clearable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Default Diskon"
                          label-cols-md="4"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Diskon"
                            rules="required|positive|min_value:0|max_value:100|twodecimal"
                          >
                            <b-input-group append="%">
                              <b-form-input
                                v-model="userData.defaultSalesDisc"
                                type="number"
                                placeholder="Default Diskon"
                              />
                            </b-input-group>
                            <small class="text-danger d-block">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </XyzTransition>
              <b-col
                cols="12"
                xl="4"
              >
                <XyzTransition
                  appear
                  xyz="fade right-3 delay-2"
                >
                  <div
                    v-if="userData.nama || userData.kode"
                    class="box"
                  >
                    <div class="sidebar">
                      <b-card>
                        <h3 class="mb-1">
                          Summary
                        </h3>
                        <h4>{{ userData.nama }}</h4>
                        <h6>{{ userData.email }}</h6>
                        <h6>{{ userData.mobilePhone }}</h6>
                        <hr>
                        <div
                          v-if="userData.kategori"
                          class="mb-50"
                        >
                          <small class="d-block">Kategori</small>
                          <b-badge variant="primary">
                            {{ userData.kategori.name }}
                          </b-badge>
                        </div>
                        <div v-if="userData.kategori_harga">
                          <small class="d-block">Kategori Harga</small>
                          <b-badge variant="warning">
                            {{ userData.kategori_harga.name }}
                          </b-badge>
                        </div>
                        <hr>
                        <h6>{{ userData.alamat }}</h6>
                        <h6 v-if="userData.kota">
                          {{ userData.kota.name }}
                        </h6>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="primary"
                          @click="loadingAdd ? null : submitForm()"
                        >
                          <span v-if="!loadingAdd">Update</span>
                          <b-spinner
                            v-else
                            small
                          />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          block
                          variant="outline-secondary"
                          @click="resetForm()"
                        >
                          Reset
                        </b-button>
                      </b-card>
                    </div>
                  </div>
                </XyzTransition>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      :error="error"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BButton, BBadge, BInputGroup, BSpinner, BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Cleave from 'vue-cleave-component'
import {
  required, min, max, email, length, positive, minValue, maxValue,
} from '@validations'
import cities from '@/db/region_json/cities.json'
import provinces from '@/db/region_json/provinces.json'
import countries from '@/db/region_json/countries.json'
import { mapGetters } from 'vuex'
import { updateCustomer } from '@/graphql/mutation'

export default {
  components: {
    BackNavigation,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BBadge,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BModal,
    vSelect,
    Cleave,
    ErrorEndpoint,
  },
  /* eslint-disable vue/require-prop-types */
  props: ['id'],
  data() {
    return {
      error: '',
      loading: true,
      loadingAdd: false,
      cleaveFormat: {
        limitPiutang: {
          numeral: true,
        },
        npwp: {
          numericOnly: true,
          delimiters: ['.', '.', '.', '-', '.'],
          blocks: [2, 3, 3, 1, 3, 3],
          uppercase: true,
        },
      },
      required,
      min,
      max,
      email,
      length,
      positive,
      minValue,
      maxValue,
      cities,
      provinces,
      countries,
      userData: {
        kode: '',
        nama: '',
        kategori: '',
        kategori_harga: '',
        limitPiutang: 0,
        email: '',
        workPhone: '',
        mobilePhone: '',
        npwp: '',
        alamat: '',
        kota: '',
        provinsi: '',
        negara: '',
        defaultSalesDisc: '',
        paymentTerm: '',
      },
      paymentTerm: [],
      loadingCheckCustomerNo: false,
      errorCustomerNo: '',
      prevData: '',
    }
  },
  computed: {
    ...mapGetters({
      customerCategory: 'getCategoryCustomer',
      priceCategory: 'getPriceCategoryCustomer',
    }),
  },
  created() {
    if (this.id.startsWith('C')) {
      this.$store.dispatch('getCustomerCategory')
      this.$store.dispatch('getPriceCategory')
      this.$store.dispatch('getPaymentTerm').then(result => {
        this.paymentTerm = result
        // eslint-disable-next-line prefer-destructuring
        this.userData.paymentTerm = result[0]
      })
      this.getCustomerDetail()
    } else {
      this.loading = 'error'
      this.error = 'Tidak dapat mengubah customer ini. Hanya bisa melalui Accurate.'
    }
  },
  methods: {
    async getCustomerDetail() {
      await this.$store.dispatch('getCustomerDetail', this.id).then(result => {
        if (result.isAssigned || this.$root.role === 2) {
          this.prevData = JSON.stringify(result)
          const data = result
          this.userData = {
            kode: data.customerNo,
            nama: data.name,
            kategori: data.category,
            kategori_harga: data.priceCategory,
            limitPiutang: data.customerLimitAmountValue,
            email: data.email,
            workPhone: data.workPhone,
            mobilePhone: data.mobilePhone,
            npwp: data.npwpNo,
            alamat: data.billStreet,
            kota: data.billCity,
            provinsi: data.billProvince,
            negara: data.billCountry,
            defaultSalesDisc: data.defaultSalesDisc,
            paymentTerm: data.term,
          }
          this.loading = false
        } else {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Kamu tidak punya akses ke halaman tersebut!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.$router.replace('/unauthorized')
        }
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    getRegionPair() {
      this.userData.provinsi = this.provinces.filter(province => province.id === this.userData.kota.province_id)[0].name
      this.userData.negara = this.countries.filter(country => country.id === this.userData.kota.country_id)[0].name
    },
    async submitForm() {
      this.$refs.validateCustomer.validate().then(async success => {
        if (success) {
          this.loadingAdd = true
          await this.$apollo.mutate({
            mutation: updateCustomer,
            variables: {
              customer_id: `${this.id}`,
              params: {
                customerNo: this.userData.kode,
                name: this.userData.nama,
                mobilePhone: this.userData.mobilePhone,
                workPhone: this.userData.workPhone,
                npwpNo: this.userData.npwp,
                email: this.userData.email,
                customerLimitAmountValue: parseFloat(this.userData.limitPiutang),
                billStreet: this.userData.alamat,
                billCity: this.userData.kota ? this.userData.kota.name : null,
                billProvince: this.userData.provinsi,
                billCountry: this.userData.negara,
                priceCategoryName: this.userData.kategori_harga ? this.userData.kategori_harga.name : null,
                categoryName: this.userData.kategori ? this.userData.kategori.name : null,
                termName: this.userData.paymentTerm.name,
                defaultSalesDisc: parseFloat(this.userData.defaultSalesDisc),
              },
            },
            context: {
              headers: {
                Authorization: this.$store.getters.getBearerToken,
              },
            },
          }).then(value => {
            this.loadingAdd = false
            if (value.data.updateCustomer.status === 'Success') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil diupdate!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.replace({ name: 'customer-detail', params: { id: this.id } })
            }
          }).catch(() => {
            this.loadingAdd = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menyimpan!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Harap Lengkapi Data Terlebih Dahulu!',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    resetForm() {
      this.$swal({
        title: 'Reset form?',
        text: 'Progress pengisian form anda akan direset dan tidak dapat dikembalikan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const data = JSON.parse(this.prevData)
          this.userData = {
            kode: data.customerNo,
            nama: data.name,
            kategori: data.category,
            kategori_harga: data.priceCategory,
            limitPiutang: data.customerLimitAmountValue,
            email: data.email,
            workPhone: data.workPhone,
            mobilePhone: data.mobilePhone,
            npwp: data.npwpNo,
            alamat: data.billStreet,
            kota: data.billCity,
            provinsi: data.billProvince,
            negara: data.billCountry,
            defaultSalesDisc: data.defaultSalesDisc,
            paymentTerm: data.term,
          }
          this.errorCustomerNo = ''
          // this.$refs.validateCustomer.reset()
        }
      })
    },
    checkCustomerNo(data) {
      this.loadingCheckCustomerNo = true
      this.$store.dispatch('checkCustomerNo', data).then(result => {
        if (!result && data !== this.prevData.customerNo) {
          this.errorCustomerNo = 'Kode ini sudah dipakai. Harap gunakan kode yang lain.'
        } else {
          this.errorCustomerNo = ''
        }

        this.loadingCheckCustomerNo = false
      })
    },
  },
}
</script>

<style>
  @media (min-width: 1200px) {
    .box {
      position: fixed;
      width: 20%;
      transition: opacity 500ms linear;
    }

    .sidebar {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
